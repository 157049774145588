import logger from 'OK/logger';

/**
* !!!ACHTUNG!!!
* Модуль перенесен в PhotoUploadDnd
* TODO: Удалить после переезда на PhotoUploadDnd
*/

var dndOverlay = null;
// свои лееры аплоада, перекрывающие этот
var otherDndSelectors = [];
// модуль для реквайра, осуществляющий сам аплоад
var uploadHandler = "";
var mediaTypes = [];
var delimiter = ";";

function classifyFiles(items) {
    var files = [];
    var supportedFiles = [];
    var unsupportedFiles = [];

    // преобразуем в массив
    for (var i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
            files.push(items[i]);
        }
    }

    files.forEach(function(item) {
        if(mediaTypes.some(function(matcher) { return item.type.match(matcher) })) {
            supportedFiles.push(item);
        } else {
            unsupportedFiles.push(item);
        }
    });
    return {
        supported: supportedFiles,
        unsupported: unsupportedFiles
    }
}

function onDragEnter(event) {
    // логируем только начало драга и открытие леера,
    // возня мышкой внутри уже открытого леера не столь интересна
    if(!dndOverlay.classList.contains('__enabled')) {
        logger.success('dnd.layer', 'drag_started');
    }
    event.preventDefault();
    var overOtherDnd = otherDndSelectors.some(function(selector) {
        return isOverOtherDnd(selector, event);
    });

    var items = event.dataTransfer && event.dataTransfer.items;
    var isShowOverlay = !OK.Layers.isAnyLayerOpened() && !overOtherDnd
        && (items.length > 0 ? classifyFiles(items).supported.length : true);

    dndOverlay.classList.toggle('__enabled', isShowOverlay);
}

function isOverOtherDnd(selector, event) {
    var otherDndElt = document.querySelector(selector);
    if(!otherDndElt) {
        return false;
    }
    var rect = otherDndElt.getBoundingClientRect();
    return rect.top <= event.y &&
        rect.bottom >= event.y &&
        rect.left <= event.x &&
        rect.right >= event.x;
}

function onDragEnd() {
    logger.success('dnd.layer', 'drag_ended');
    dndOverlay.classList.toggle('__enabled', false);
}

function onDrop(event) {
    logger.success('dnd.layer', 'dropped');
    event.preventDefault();
    dndOverlay.classList.toggle('__enabled', false);
    var items = event.dataTransfer && event.dataTransfer.items;
    var classified = classifyFiles(items);
    var supportedFiles = classified.supported;
    var unsupportedFiles = classified.unsupported;

    supportedFiles = supportedFiles.map(function(item) {
        return item.getAsFile();
    });

    if(supportedFiles.length) {
        import(uploadHandler).then(m => m && m.default || m).then(function(uploadModule){
            uploadModule.performUpload(supportedFiles);
        });
    }
    unsupportedFiles.forEach(function(item) {
        logger.success('dnd.layer', 'unsupported_file_type', item.type);
    });
    logger.success('dnd.layer', 'uploaded');
}

function activate(element) {
    dndOverlay = element.querySelector('.dnd-overlay');
    uploadHandler = dndOverlay.getAttribute('data-upload-handler');
    otherDndSelectors = dndOverlay.getAttribute('data-exclude-selectors').split(delimiter);
    mediaTypes = dndOverlay.getAttribute('data-media-types').split(delimiter);

    if (dndOverlay) {
        document.body.addEventListener('dragenter', onDragEnter);
        dndOverlay.addEventListener('dragover', onDragEnter);
        dndOverlay.addEventListener('dragleave', onDragEnd);
        dndOverlay.addEventListener('dragend', onDragEnd);
        dndOverlay.addEventListener('drop', onDrop);
    }
}

function deactivate() {
    if (dndOverlay) {
        document.body.removeEventListener('dragenter', onDragEnter);
        dndOverlay.removeEventListener('dragover', onDragEnter);
        dndOverlay.removeEventListener('dragleave', onDragEnd);
        dndOverlay.removeEventListener('dragend', onDragEnd);
        dndOverlay.removeEventListener('drop', onDrop);
    }
}

export default { activate, deactivate };

export { activate, deactivate };
